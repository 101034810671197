import React,{useState,useCallback} from 'react'
//import {useUploadyContext} from "@rpldy/uploady";
import throttle from 'lodash.throttle'
import UploadBtn from './upload-btn-new'
//import Progress from './file-progress'

const FileUploader = ({onSuccess,onError,onFileSelected,isLoading,...props}) => {
    const [disabled, setDisabled] = useState(true)
    const [emailField,setEmail]=useState(null)
   // const uploadyContext=useUploadyContext()
    const onChange=useCallback(
      (e)=>{
        setDisabled(e.target.value.length<1)
        setEmail(e.target.value)
    },
      [],
    )
   
    return (
        <div>
          {!isLoading &&<div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Registered Email
            </label>
            <input
             type="email"
             onChange={throttle(onChange,300)}
             title={emailField}
             placeholder="Enter your registered email"
             className="shadow-sm w-full rounded  leading-tight border-gray-300 focus:border-black focus:ring-black" 
             name="" 
             id="email"/>
             </div>}
                <UploadBtn
                  onFileSelected={onFileSelected}
                  onError={onError}
                  onSuccess={onSuccess}
                  className="bg-red-800 disabled:opacity-50 text-white text-xs mb-6  border py-4 px-4 font-bold border-gray-100 rounded uppercase tracking-wide disable" 
                  disabled={disabled || isLoading}  
                  params={{emailField,...props.params}} 
                  {...props} title={!isLoading?'Upload File':'...uploading!'} 
                />
        
     </div>
    )
}

export default FileUploader
