import React,{useRef,useCallback} from 'react'

const Component = ({filename,url,title,params,onSuccess,onError,disabled,className,onFileSelected}) => {
    const fileRef= useRef(null)
    const handleClick= () => {
            fileRef.current.click()
        }
       
   const handleFileChange=useCallback(
       (e) => {
           
         onFileSelected &&onFileSelected(e)  
        var reader = new FileReader();
        var file = e.target.files[0];
    
        reader.onload = function(f) {
            const qs = new URLSearchParams({filename: filename || file.name, mimeType: file.type,...params});
            fetch(`${url}?${qs}`, {method: "POST", body: JSON.stringify([...new Int8Array(f.target.result)])})
            .then(res => res.json())
            .then(e => onSuccess && onSuccess(e)) 
            .catch(err => onError && onError(e));
        };

        reader.readAsArrayBuffer(file);
       },
       [filename, onError, onFileSelected, onSuccess, params, url],
   )

    return (
        <div>
        <input ref={fileRef}  onChange={handleFileChange} type='file' className='hidden'/>
        <button disabled={disabled} onClick={handleClick} className={className}  >
            {title||'Upload'}
        </button>
        </div>
    )
}


export default Component
