
import Main from './main'

import './App.css';


function App() {
 return <Main/>
    
}

export default App;
