import React,{useState} from 'react'
import FileUploader from './file-uploader'
//import Instructions from './instructions'
const URL=process.env.NODE_ENV==='production'?'https://script.google.com/macros/s/AKfycbzyxxUWnvOkxvWvIz5SOgR8_BczVDufZ-tir_a2uGmZejA-5Bq3kGMjxXt9C6ZxlGqVyg/exec':
'/macros/s/AKfycbzyxxUWnvOkxvWvIz5SOgR8_BczVDufZ-tir_a2uGmZejA-5Bq3kGMjxXt9C6ZxlGqVyg/exec';
const Component = props => {
    const [isLoading,setLoading]=useState(false)
    const [message,setSuccess]=useState()
    const [errorMsg,setError]=useState()
    const [showUi,setShowUI]=useState(true)
    const onFileSelected=()=>{
        setLoading(true)
    }
    const onSuccess=()=>{
        setSuccess('Successfully Uploaded File')
        setLoading(false)
        setShowUI(false)
    }
    const onError=(e)=>{
        setError('Problem loading file, contact Ilcaffe support at +234808 448 9684 or try again');
        setLoading(false)
        setShowUI(false)
    }
  return (
    <div className="max-w-sm m-auto">
        <div className="bg-white shadow-md rounded px-10 pt-6 pb-8 mb-4">
            {message&&!isLoading&&<h2 className='text-xl text-center font-bold p-3 rounded text-white bg-green-500'>{message}</h2>}
            {errorMsg&&!isLoading&&<h2 className='text-lg text-center font-bold p-3 rounded text-white bg-red-500'>{errorMsg}</h2>}
        {showUi && 
        <FileUploader 
             onError={onError} 
             onSuccess={onSuccess}
             onFileSelected={onFileSelected} 
             isLoading={isLoading} 
             url={URL}
          />
          }
    </div>
        <p className="text-center text-gray-500 text-xs">
          &copy;2020 Ilcaffe ltd . All rights reserved.
        </p>
      </div>
    
    )
}


export default Component
